@import '../designTokens';

.agent-action-bar {
  display: flex;
  flex-direction: column;
  //background-color: rgba(220, 220, 220, 0.88);

  button {
    margin-bottom: 0.5rem;
    background: transparent;
    border: none;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.agent-portrait {
  border-radius: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
  }

  img {
    margin: 2px;
    border-radius: 0.5rem 0.5rem;
    width: 164px;
    height: 164px;
  }
}

.agent-card {
  background-color: rgba(220, 220, 220, 0.88);
  border-radius: 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
}

.speak-out-popover {
  .popover-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.play-button {
  background-color: transparent;
  border: none;
}
.play-button-img {
  width: 24px;
  height: 24px;
}