@import "../designTokens";

.spinner-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  color: white;
  width: 100%;
  height: 100%;

  background-image: url("../assets/hearth_bg4.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.spinner-container {
  width: 100%;
  height: 100%;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $accentColor;

  .spinner {
    height: 200px;
    width: 200px;
  }
}