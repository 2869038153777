@import "../designTokens";

.choice-card {
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.66);
  h4 {
    color: #600;
  }
  .choice-button-row {
    display: flex;
  }

  .choice-button {
    margin-left: auto;
    background-color: #500;
    color: #ddd;
    width: 128px;
  }
}

.choice-card:nth-child(2) {
  margin-top: 1rem;
}