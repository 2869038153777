@import "../designTokens";

$font-family-base: "Playfair Display", serif;

.home-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .nav-container {
    width: 100%;
    .nav-title {
      width: 100%;
      color: #ccc;
    }
  }

  background-image: url("../assets/hearth_bg3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.game-card-container {
  width: 90%;
  min-width: 1060px;
}

.welcome-text {
  background-color: $backgroundColorDarkBlue;
  opacity: 0.7;
}

#root {
  height: 100%;
}