@import "../designTokens";

.event-card {
  padding: 0.75rem;
  background-color: rgba(255, 255, 255, 0.66);
}

.outcome-card {
  padding: 0.75rem;
  background-color: rgba(255, 255, 255, 0.66);
}

.outcome-button-row {
  display: flex;
}

.outcome-button {
  margin-left: auto;
  background-color: #500;
  color: #ddd;
  width: 128px;
}

.event-header {
  h4 {
    margin: 0;
    font-weight: bold;
  }
  h3 {
    margin: 0;
    font-weight: bold;
  }
  display: flex;
  align-items: center;
  .play-button {
    background-color: transparent;
    border: none;
  }
  .play-button-img {
    width: 24px;
    height: 24px;
  }
}