@import "../designTokens";

.game-card {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  overflow:hidden;
  min-width: 340px;
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;

  .card-body {
    background-color: $backgroundColorDarkBlueOpaque;
    .img-border {
      border: 2px solid $accentColor;
      border-radius: 0.5rem 0.5rem;
      height: fit-content;
      overflow: hidden;
    }
    img {
      width: 65px;
      height: 65px;
    }
  }
  .character-info {
    display: flex;
  }
  .basic-stats {
    > .description {
      font-size: 9pt;
      color: #bbb;
    }
  }
  .card-footer {
    background-color: $backgroundColorAccentOpaque;
  }
}

.new-event-button {
  background-color: #500;
  color: #ddd;
  width: 128px;
}