@import "../designTokens";

.no-characters {
  font-size: x-large;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.welcome-info {
  background-color: rgba(255, 255, 255, 0.66);
  padding: $spacing-medium $spacing-large 0 $spacing-medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $spacing-large;
  border: 2px solid rgba(100,100,100, 0.50);
  border-radius: 0.5rem 0.5rem;
  h3 {
    font-size: x-large;
    margin-bottom: $spacing-medium;
  }
  p {
    font-size: large;
  }
}

.get-started-button {
  margin-left: auto;
  margin-bottom: 1rem;
  background-color: #500;
  color: #ddd;
  width: 128px;
}
.get-started-button:hover {
  background-color: $backgroundColorAccent;
  border: 2px solid $accentColor;
  color: #ccc;
  opacity: 0.9;
}

.game-list {
  //display: grid;
  //gap: $spacing-large;
  //grid-template-columns: 1fr 1fr 1fr;
  //justify-content: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  align-items: center;
}

.game-list-item {
  padding: 0.5rem;
  margin-top: 1rem;
  width: 50%;
  background-color: rgba(255, 255, 255, 0.66);
}
.game-list-item-row {
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
}

.new-game-button {
  background-color: #500;
  color: #ddd;
}

.game-list-resume-button {
  margin-left: auto;
  background-color: #500;
  color: #ddd;
}

.new-game {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .games-list {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1em;
    grid-row-gap: $spacing-large;
  }
}

.games-list > * {
  max-width: 400px;
  background-color: transparent;
}

.card-footer {
  .nav-pills {
    .nav-link {
      a {
        color: #bbb;
      }
    }
  }
}